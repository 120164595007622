/* Welcome to Compass. Use this file to write IE specific override styles.
 * Import this file using the following HTML or equivalent:
 * <!--[if IE]>
 *   <link href="/stylesheets/ie.css" media="screen, projection" rel="stylesheet" type="text/css" />
 * <![endif]--> */

.programa-descripcion::after {
  content: "";
  position: absolute;
  top: 99%;
  left: 48.5% !important;
  right: 0px;
  margin: 0 auto;
  width: 0px;
  height: 0px;
  border-top: solid 20px #89a9c4;
  border-left: solid 20px transparent;
  border-right: solid 20px transparent;
}


